/**
 * Spinner
 */

export function spinner () {
  // Show
  var create = function () {
    var elem = document.createElement('div')
    elem.setAttribute('id', 'spinner')
    elem.innerHTML =
      '<div class="sk-circle">' +
      '<div class="sk-circle1 sk-child"></div>' +
      '<div class="sk-circle2 sk-child"></div>' +
      '<div class="sk-circle3 sk-child"></div>' +
      '<div class="sk-circle4 sk-child"></div>' +
      '<div class="sk-circle5 sk-child"></div>' +
      '<div class="sk-circle6 sk-child"></div>' +
      '<div class="sk-circle7 sk-child"></div>' +
      '<div class="sk-circle8 sk-child"></div>' +
      '<div class="sk-circle9 sk-child"></div>' +
      '<div class="sk-circle10 sk-child"></div>' +
      '<div class="sk-circle11 sk-child"></div>' +
      '<div class="sk-circle12 sk-child"></div>' +
      '</div>';

    document.body.appendChild(elem)
    show(elem)
  };

  var show = function (elem) {
    // elem.className += 'show'
    var el = document.getElementById('spinner')
    el.classList.add('show')
  };

  // Hide
  var hide = function () {
    var el = document.getElementById('spinner')
    // el.parentNode.removeChild(el)
    el.classList.remove('show')
  };

  return {
    create: create,
    show: show,
    hide: hide
  }
}

export default spinner

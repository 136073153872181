/**
 * Carousel
 * https://flickity.metafizzy.co/
 */

import Flickity from 'flickity'
import 'flickity/css/flickity.css'

export function carousel () {
  let carousel = document.getElementsByClassName('js-carousel');
  for( var i = 0; i <= carousel.length; i++) {
    var thisCarousel = carousel[i];

    if (thisCarousel) {
      // Flickity - fade in for no FOUC, vanilla JS
      // https://codepen.io/desandro/pen/JGoGpm
      thisCarousel.classList.remove('is-hidden')
      
      var playtime = false;
      var drag = true;
      var pause = true;
      
      if (thisCarousel.classList.contains('carousel-home')) {
        var playtime = 5000;
      }

      var cell = thisCarousel.querySelectorAll('.carousel-cell').length;

      if (cell > 1) {
        var dots = true;
      }
      var Flickity = require('flickity-fade');
      var Flickity = require('flickity-bg-lazyload');
      
      // carousel.offsetHeight
      var flkty = new Flickity(thisCarousel, {
        autoPlay: playtime,
        pauseAutoPlayOnHover: pause,
        prevNextButtons: false,
        pageDots: dots,
        bgLazyLoad: true,
        // adaptiveHeight: true,
        lazyLoad: true,        
        draggable: drag,
        fade: true
      });

      var imgs = thisCarousel.querySelectorAll('.carousel-cell img')
      // get transform property
      // var docStyle = document.documentElement.style
      // var transformProp = typeof docStyle.transform === 'string' ? 'transform' : 'WebkitTransform'

      // flkty.on('scroll', function () {
      //   flkty.slides.forEach(function (slide, i) {
      //     var img = imgs[i]
      //     var x = ((slide.target + flkty.x) * -1) / 3
      //     img.style[transformProp] = 'translateX(' + x + 'px)'
      //   })
      // })
    }
  }
    
}

export default carousel

/**
 * Translate Y Animation on scroll 
 */

import * as ScrollMagic from "scrollmagic";
import { gsap, TweenMax, TimelineMax } from 'gsap';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)
// import ScrollMagic from "scrollmagic";
// import "scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";
// import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";

export function animTranslateYOnScroll (elem) {
    
    var animTranslateYOnScrollTooSmall;
    var animTranslateYOnScrollController;
    var animTranslateYOnScrollResizing;
    var animTranslateYOnScrollMaxWidth;

  // Show
  var create = function () {
    
    animTranslateYOnScrollTooSmall = false;
    animTranslateYOnScrollController = null;
    animTranslateYOnScrollResizing = false;
    animTranslateYOnScrollMaxWidth = 740;

    if ($(window).outerWidth() < animTranslateYOnScrollMaxWidth) {
        animTranslateYOnScrollTooSmall = true;
        animTranslateYOnScrollRestore();
    }

    if (!animTranslateYOnScrollTooSmall) {
        animTranslateYOnScrollInit();
    }

    // part of the problem is that window resizes can trigger multiple times as the events fire rapidly
    // this solution prevents the controller from being initialized/destroyed more than once
    $(window).on('resizeend', 250, function () {
        if (!animTranslateYOnScrollResizing) {
          animTranslateYOnScrollResizing = true;
          animTranslateYOnScrollResize();
          setTimeout(animTranslateYOnScrollResizemanager, 100);
        }
      })
  };

  var animTranslateYOnScrollRestore = function () {
    $(elem).removeAttr('style')
  };

  // Init
  var animTranslateYOnScrollInit = function () {

    animTranslateYOnScrollController = new ScrollMagic.Controller();
  
    var end = $(elem).data('translateYEnd');
    var start = -($(elem).height() * (end / 4)) / 100;   

    if ($(elem).data('translateYStart') != 0) {
      start = $(elem).data('translateYStart');
    }
    var tween = new TimelineMax();
    
    tween.add([
      TweenMax.fromTo(
        $(elem),
        1,
        {
          x: 0,
          y: 0,
          z: 0,
          yPercent: start,
          xPercent: 0,
          force3D: true
        },
        {
          x: 0,
          y: 0,
          z: 0,
          yPercent: end,
          xPercent: 0,
          force3D: true,
          ease: "none"
        }
      )
    ]);

    var scene = new ScrollMagic.Scene({
      triggerElement: elem,
      duration: $(window).height() * 1.5,
      triggerHook: 'onEnter'
    })
      .setTween(tween)
      // .addIndicators() // add indicators (requires plugin)
      .addTo(animTranslateYOnScrollController)

    if ($(elem).data('translateHover')) {
      $(elem).hover(
        function () {
          TweenMax.to($(elem).find('img'), 0.8, {
            css: { scale: 1.05 },
            ease: Circ.easeOut
          })
        },
        function () {
          TweenMax.to($(elem).find('img'), 0.8, {
            css: { scale: 1 },
            ease: Circ.easeOut
          })
        }
      )
    }
  };

  var animTranslateYOnScrollResizemanager = function () {
    animTranslateYOnScrollResizing = false
  }

  var animTranslateYOnScrollResize = function () {
    animTranslateYOnScrollRestore()

    if (
      animTranslateYOnScrollController !== null &&
      animTranslateYOnScrollController !== undefined
    ) {
      // completely destroy the controller
      animTranslateYOnScrollController = animTranslateYOnScrollController.destroy(
        true
      )
    }

    if ($(window).outerWidth() > animTranslateYOnScrollMaxWidth) {
      animTranslateYOnScrollInit()
    }
  }

//   return {
//     create: create,
//     restore: animTranslateYOnScrollRestore,
//     init: animTranslateYOnScrollInit,
//     resize : animTranslateYOnScrollResize,
//     resizemanager: animTranslateYOnScrollResizemanager
//   }

  create();
  
}

export default animTranslateYOnScroll

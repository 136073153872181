/*
 * @title App
 * @description Application entry point
 */
// Jquery
import $ from "jquery";
window.jQuery = $;
window.$ = $;
var Modernizr = require("browsernizr");

// Barba - pages transition
import barba from "@barba/core";
import barbaCss from "@barba/css";


// Gallery
import lightGallery from "lightgallery";
import "lg-video";

// Modal
import modal from "jquery-modal";

// Lazyload image
import LazyLoad from "vanilla-lazyload";

// Reveal element on scroll
import ScrollReveal from "scrollreveal";

// Infinite scroll
var InfiniteScroll = require("infinite-scroll");

// Google maps api
import { Loader } from '@googlemaps/js-api-loader';

// Components
import spinner from "./components/spinner";

import animTranslateYOnScroll from "./components/thom_animation_translateYOnScroll";

import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';

// make Masonry a jQuery plugin
jQueryBridget( 'masonry', Masonry, $ );

var imagesLoaded = require('imagesloaded');

require("jquery.resizeend");

// require("jquery-hoverintent");
// require("malihu-custom-scrollbar-plugin");

// Thom Form
require("./components/thom_form");


// Modules
import navbar from "../modules/navbar/navbar";
import carousel from "../modules/carousel/carousel";
// require("../modules/dropdown/dropdown");
import thomDropdown from "../modules/dropdown/dropdown";

// paginationom "twbs-pagination"
require("twbs-pagination")

// exist element
$.fn.exists = function() {
  return this.length !== 0;
};

// Form Response Action
function MyThomFormResponseAction(title, text) {
  var mytitle;
  if (title !== undefined && title !== null && title !== "") {
    mytitle = "<h1><strong>" + title + "</strong></h1>";
  } else {
    mytitle = "";
  }

  var myHtml =
    '<div class="ajax-load">' +
    mytitle +
    '<div class="privacy__content">' +
    "<p>" +
    text +
    "</p>" +
    "</div>" +
    "</div>";

  $(myHtml)
    .appendTo("body")
    .modal();
}

function AviabilitySearch() {
  var filterdata = {};
  var pageN = 1;
  // var isPageClick = false;
  var filters = $('.thom-search--filter-block');   
      
  // filters click
  filters.each(function(){
    var _ = $(this);
    _.find('.thom-search--filter-btn').click(function (e) {      
      // isPageClick = false;
      pageN = 1;
      _.find('.thom-search--filter-btn').removeClass('is-selected');
      $(this).addClass('is-selected');
      $('.thom-search--text-input').val('');
      GetSearchResults();
    });
  });

  // search submit click
  $('.thom-search--text-submit').click(function (e) {
    e.preventDefault();
    // isPageClick = false;
    pageN = 1;
    $('.thom-search--filter-btn').removeClass('is-selected');
    filters.each(function(){
      var _ = $(this);
      _.find('.thom-search--filter-btn').first().addClass('is-selected');
    });
    GetSearchResults();
  });

  $('.thom-search--inputsearch .thom-search--text-input').keypress(function(event){
    if(event.keyCode == 13){
      $('.thom-search--text-submit').click();
    }
  });

  // pagination
  var $pagination = $('.pagination');
  var defaultOpts = {
    // hideOnlyOnePage: true,
    totalPages: 25,
    visiblePages: 2,
    cssStyle: '',
    first: '<i class="icon-arrow_page-first"></i>',
    last: '<i class="icon-arrow_page-last"></i>',
    prev: '<i class="icon-arrow_page-prev"></i>',
    next: '<i class="icon-arrow_page-next"></i>',
    onPageClick: function (event, page) {
      pageN = page;
      // isPageClick = true;
      GetSearchResults();
    },
    initiateStartPageClick: false
  };
  $pagination.twbsPagination(defaultOpts);

  function GetFilters(){

    for( var i = 0; i < filters.length; i++){
      var filterId = $(filters[i]).attr('id');
      var filterValue = $(filters[i]).find('.thom-search--filter-btn.is-selected').attr('id');
      if(filterValue === undefined){
        filterValue = null;
      }
      filterdata[filterId] = filterValue
    }

    // input search value
    filterdata['search'] = $('.thom-search--text-input').val();
    // current page
    filterdata[$('.pagination').attr('id')] = pageN;

    return filterdata;
  }

  function GetSearchResults(){
    spinner().show();

    $.ajax({
      type: 'POST',
      // contentType: 'application/json;charset=utf-8',
      url: THOM_searchUrlContent,
      data: GetFilters(),
      dataType: 'html',
      success: function (data) {  
        // console.log('success')
        $('#search-result').html(data);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        console.log('error')
        console.log(xhr);
        console.log(ajaxOptions);
      },
      complete: function (xhr) {
        // console.log('complete')
        // console.log(xhr);
        if(xhr.statusText === "success" || xhr.statusText === "OK") {  
          // console.log('1')
          var totalresult = $('#search-result').find('table').data('result');
          // pagination replace
          var resultPerPage =  $('#quantity').find('.thom-search--filter-btn.is-selected').attr('id');
          if(resultPerPage === undefined || resultPerPage === "undefined" || resultPerPage === null){
            resultPerPage = 1;
          }
          // console.log('2')
          if(totalresult > resultPerPage){
            var totalPages = totalresult / resultPerPage;
            if(!Number.isInteger(totalPages)){
              totalPages = Math.floor(totalPages) + 1
            }
          } else {
            totalPages = 1;
          }
          // console.log('3')
          var currentPage = $pagination.twbsPagination('getCurrentPage');
          if(currentPage > totalPages || !$.isNumeric(currentPage) || pageN === 1){
            currentPage = 1;
          }
          // console.log('4')
          $pagination.twbsPagination('destroy');
          // console.log('5')
          $pagination.twbsPagination($.extend({}, defaultOpts, {
            startPage: currentPage,
            totalPages: totalPages
          }));
          // console.log('6')

          $('html,body').animate({
            scrollTop: 0
          }, 600);
          
          spinner().hide();
        }
      }
    })
  }

  GetSearchResults();
}

function myapp() {

  // Footer News
  if($(".main").data('barba-namespace') === 'homepage'){
    $('.footer-news').show();
  } else {
    $('.footer-news').hide();
  }

  var myLazyLoad = new LazyLoad({
    elements_selector: ".lazy"
  });
  // myLazyLoad.update()

  // Reveal element on scroll
  var sr = ScrollReveal();
  sr.reveal(".reveal", {
    duration: 800,
    delay: 300,
    mobile: false
  });

  // Carousel
  carousel();

  // Dropdown
  thomDropdown();

  // Contacts Map
  if ($(".contact-map").exists() && $(".contact-map #map").exists()) {
    var map;
    const GoogleMapsLoader = new Loader({
      apiKey: _gmapkey,
      version: "weekly",
      libraries: ["places"]
    });

    
    GoogleMapsLoader
    .load()
    .then(() => {
      map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: params.latitude, lng: params.longitude },
        zoom: 8,
        maxZoom: 18,
        streetViewControl: false,
        mapTypeControl: false,
        scrollwheel: false,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
        },
        styles: [    
          {
              featureType: "poi",
              // elementType: "labels",
              stylers: [{ visibility: "off" }]
          }
        ]
      });

      var image = {
        url: "" + _root + "img/marker.png",
        size: new google.maps.Size(77, 60)
      };
      var myMarker = new google.maps.Marker({
        position: { lat: params.latitude, lng: params.longitude },
        map: map,
        icon: image
      });
      var bounds = new google.maps.LatLngBounds();
      bounds.extend(myMarker.getPosition());
      map.fitBounds(bounds);
    })
    .catch(e => {
      // do something
    });
  }

  // Contacts Form
  if ($(".contact-form .thom-form").exists()) {
    $(".contact-form .thom-form").each(function() {
      var $this = $(this);
      $this.thomForm({
        errorMsg: $this.find(".thom-form__error"),
        errorCallback: function(myTitle, myMessage) {
          $("html,body").animate(
            {
              scrollTop: $this.offset().top - $(".navbar").height() * 2
            },
            800
          );

          if (myTitle) {
            myTitle = "<h1>" + myTitle + "</h1>";
          }
          if (myMessage) {
            myMessage = "<p>" + myMessage + "</p>";
          }

          if (myTitle || myMessage) {
            $this.find(".thom-form__error").html(myTitle + myMessage);
          }

          $this.find(".thom-form__error").fadeIn(300);
        },
        myCallback: function(myTitle, myMessage) {
          MyThomFormResponseAction(myTitle, myMessage);
        }
      });
    });
  }

  // Aviability
  // Filters
  if ($(".thom-search--filter-block").exists()){
    AviabilitySearch();
  }
  
  // scroll to div
  if ($('.scrollto').exists()) {
    $('.scrollto').click(function(e){
      e.preventDefault();
      var link = $(this).data('link');
      console.log(link)
      $("html,body").animate(
        {
          scrollTop: $('#' + link).offset().top - $('.navbar').height()
        },
        600
      );
    })
  }

  // Translate Y Animation on scroll
  if ($('[data-translate-y-end]').exists()) {
    $('[data-translate-y-end]').each(function () {
      animTranslateYOnScroll(this);
    })
  }

  // open image popup
  $(".gallery__btn").lightGallery({
    selector: ".gallery__item",
    download: false,
    counter: false
  });

  // Open gallery from photogallery button
  if ($('.btn-gallery').exists()) {
    $('.btn-gallery').each(function () {
      var $this = $(this);
      var $lggallery = $('#' + $this.data('gallery'));

      $lggallery.lightGallery({
        selector: 'img',
        download: false,
        counter: false
      });

      $this.click(function (e) {
        e.preventDefault()
        $lggallery
          .find('img')
          .first()
          .trigger('click')
      });
    })
  }

  // open video popup
  $(".btn-video").lightGallery({
    selector: "this",
    download: false,
    counter: false,
    videoMaxWidth: "100%",
    getCaptionFromTitleOrAlt: false,
    youtubePlayerParams: {
      modestbranding: 1,
      showinfo: 0,
      rel: 0,
      controls: 0
    },
    vimeoPlayerParams: {
      byline: 0,
      portrait: 0
    }
  });  

  // Ajax modal
  $('a[rel="ajax:modal"]').click(function(event) {
    event.preventDefault();
    this.blur();
    $.ajax({
      url: $(this).attr("href"),
      success: function(newHTML, textStatus, jqXHR) {
        var myHtml = $(newHTML).find(".ajax-load");
        $(myHtml)
          .appendTo("body")
          .modal({
            fadeDuration: 250,
            fadeDelay: 1.1,
            closeClass: "icon-remove",
            closeText: "",
            spinnerHtml:
              '<div class="spinner"><div class="sk-circle"><div class="sk-circle1 sk-child"></div><div class="sk-circle2 sk-child"></div><div class="sk-circle3 sk-child"></div><div class="sk-circle4 sk-child"></div><div class="sk-circle5 sk-child"></div><div class="sk-circle6 sk-child"></div><div class="sk-circle7 sk-child"></div><div class="sk-circle8 sk-child"></div><div class="sk-circle9 sk-child"></div><div class="sk-circle10 sk-child"></div><div class="sk-circle11 sk-child"></div><div class="sk-circle12 sk-child"></div></div></div>'
          });
      },
      error: function(jqXHR, textStatus, errorThrown) {
        // Handle AJAX errors
      },
      complete: function() {}
      // More AJAX customization goes here.
    });

    return false;
  });

  if (typeof analyticsUpdate === "function") {
    analyticsUpdate();
  }
}

var playHomeVideo = 0;

// start script
barba.use(barbaCss);

// define a global hook
barba.hooks.once(data => {
  // this hook will be called for each transitions
  // console.log('APPEAR - global hook')
  
  if($('.main').attr('data-barba-namespace') === 'homepage'){
    playHomeVideo = 1;
  } else {
    playHomeVideo = 0;
  }
  
  navbar();
  myapp();

  // Remove appended div to modal after close
  $(document).on($.modal.AFTER_CLOSE, function(event, modal) {
    $(".ajax-load.modal").remove();
  });

  // Analytics update
  $(document).on($.modal.OPEN, function(event, modal) {
    if (typeof analyticsUpdate === "function") {
      analyticsUpdate();
    }
    $(modal.$anchor).find(".close-modal").attr("data-barba-prevent", "self");
  });

  $(document).on($.modal.CLOSE, function(event, modal) {
    if (typeof analyticsUpdate === "function") {
      analyticsUpdate();
    }
  });

  

  $("#spinner").removeClass("default");
});

barba.hooks.enter(data => {
  $.ajax({
    url: data.next.url.href,
    success: function(newHTML, textStatus, jqXHR) {
      // Meta update
      var headTags = [
        "meta[name='keywords']",
        "meta[name='description']",
        "meta[property^='og']",
        "meta[name^='twitter']",
        "meta[itemprop]",
        "link[itemprop]",
        "link[rel='prev']",
        "link[rel='next']",
        "link[rel='canonical']"
      ].join(",");
      $("head")
        .find(headTags)
        .remove();
      $(newHTML)
        .find(headTags)
        .appendTo("head");

      // Section placeholder Change
      var newplaceholdersection = $(newHTML)
        .find(".placeholder-section")
        .html();
      $(".navbar .placeholder-section").html(newplaceholdersection);

      // Menu Change
      var newmenu = $(newHTML)
        .find(".navbar__collapse")
        .html();
      $(".navbar .navbar__collapse").html(newmenu);

      // Footer
      // var newfooter = $(newHTML).find('.footer-path').html();
      // $(".footer .footer-path").html(newfooter);

      // script variables
      var js = $(newHTML).find("script");

      if (js != null) {
        $.globalEval(js.html());
      }

      myapp();
    },
    error: function(xhr, ajaxOptions, thrownError) {
      // alert(xhr.status)
      // alert(xhr.statusText)
      // alert(thrownError)
    },
    complete: function() {
      spinner().hide();
    }
  });
});
barba.hooks.leave(data => {
  // this hook will be called for each transitions
  // console.log("LEAVE - global hook");
  spinner().show();

  document.body.classList.remove("navbar--opened");

  $("html,body").animate(
    {
      scrollTop: 0
    },
    800
  );
});
barba.hooks.before(data => {
  // update menu
  // let menu = $('.navbar')
  // // select the menu item depending on the next URL (you can do that in many ways)
  // let nextItem = menu.find("a[href|='" + data.next.url.path + "']")
  // // reset the active menu item and set the next item as "active" (if there is one)
  // if (nextItem !== null) {
  //   menu.find('li.selected').removeClass('selected')
  //   nextItem.parent().addClass('selected')
  // }
  // update placeholder section
  // let nextPlaceholder = menu.find('.placeholder-section').text()
});

// Basic default transition, with no rules and minimal hooks…
barba.init({
  timeout: 5000,
  cacheIgnore: true,
  prefetchIgnore: true,
  debug: false,
  views: [
    {
      namespace: "contacts",
      afterEnter(data) {
        if ($(".thom-form__recaptcha").length != 0) {
          recaptchaLoad();
        }

        if ($(".mtcaptcha").length != 0) {
          mt_jsloadedcb();
        }
      }
    }
  ]
});

$(function() {});
